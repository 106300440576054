import React from "react";
import { Image } from "react-bootstrap";
import Icons from "../assets/Icons";
import { getDateLocale, getDetailLink, getItemPrimaryImage } from "../utils/utilFunction";
const ReleatedCard = ({item , loading}) => {
  return (
   loading ? <h3>Yükleniyor...</h3> : <div className="box">
      <a href={getDetailLink(item)}>
        <div className="image">
          <Image alt={item.title} src={getItemPrimaryImage(item.primaryImage)} />
        </div>
        <div className="text">
          <div className="description">{item.definition}</div>
          <div className="title">{item.title}</div>
          <div className="abstract">{item.relationName}</div>
          <div className="icons">
            <span 
              dangerouslySetInnerHTML={{ __html: Icons.calendar }}
              className="_icon"
            />
            <span> {getDateLocale(item.releaseDate)}</span>
          </div>
        </div>
      </a>
    </div>
  );
};

export default ReleatedCard;
