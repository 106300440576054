const WorkGroup = () => {
    return (
       
        <div class="page-content relative">
            <p>Prof. Dr. Yasemin DOĞANER – Komisyon Başkanı</p>
            <p>Prof. Dr. Abdullah İLGAZİ – Üye</p>
            <p>Prof. Dr. Erdal AYDOĞAN- Üye</p>
            <p>Prof. Dr. Hakan UZUN- Üye</p>
            <p>Prof. Dr. Mehmet TEMEL – Üye</p>
            <p>Prof. Dr. Mithat AYDIN – Üye</p>
            <p>Prof. Dr. Zehra ASLAN – Üye</p>
            <p>Doç. Dr. Hatice GÜZEL MUMYAKMAZ – Üye</p>
            <p>Doç. Dr. Nurten ÇETİN – Üye</p>
            <p>Doç. Dr. Orhan AVCI- Üye</p>
            <p>&nbsp;</p>
            <h3>PROJEDEN SORUMLU UZMANLAR</h3>
            <p>Merve UĞUR – Bilimsel Çalışmalar Müdür V.</p>
            <p>Selcan KOÇASLAN- Yüksek Kurum Uzmanı</p>
            <p>Orhan İSTANBUL – Yüksek Kurum Uzmanı</p>
        </div>
        
    )
}
export default WorkGroup