const Guide = () => {
    return (
       <div>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti iste maiores, natus obcaecati quasi rerum tempore vel? Ab assumenda consectetur dolore ducimus explicabo, laborum laudantium minus provident quaerat quia ratione? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquid aperiam eaque laborum maxime mollitia optio quos tempora! Dolore eveniet, impedit laborum modi nesciunt nisi obcaecati quod sequi sunt totam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto assumenda at atque aut cupiditate delectus earum illo in iste iure minus molestias, nisi officiis quibusdam quos, rem, saepe soluta veniam! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad architecto est eveniet explicabo in, incidunt itaque, perspiciatis quod, ratione sed sunt suscipit ut! Ea error explicabo nihil nobis quaerat quos?</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti iste maiores, natus obcaecati quasi rerum tempore vel? Ab assumenda consectetur dolore ducimus explicabo, laborum laudantium minus provident quaerat quia ratione? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquid aperiam eaque laborum maxime mollitia optio quos tempora! Dolore eveniet, impedit laborum modi nesciunt nisi obcaecati quod sequi sunt totam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto assumenda at atque aut cupiditate delectus earum illo in iste iure minus molestias, nisi officiis quibusdam quos, rem, saepe soluta veniam! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad architecto est eveniet explicabo in, incidunt itaque, perspiciatis quod, ratione sed sunt suscipit ut! Ea error explicabo nihil nobis quaerat quos?</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti iste maiores, natus obcaecati quasi rerum tempore vel? Ab assumenda consectetur dolore ducimus explicabo, laborum laudantium minus provident quaerat quia ratione? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquid aperiam eaque laborum maxime mollitia optio quos tempora! Dolore eveniet, impedit laborum modi nesciunt nisi obcaecati quod sequi sunt totam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto assumenda at atque aut cupiditate delectus earum illo in iste iure minus molestias, nisi officiis quibusdam quos, rem, saepe soluta veniam! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad architecto est eveniet explicabo in, incidunt itaque, perspiciatis quod, ratione sed sunt suscipit ut! Ea error explicabo nihil nobis quaerat quos?</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti iste maiores, natus obcaecati quasi rerum tempore vel? Ab assumenda consectetur dolore ducimus explicabo, laborum laudantium minus provident quaerat quia ratione? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquid aperiam eaque laborum maxime mollitia optio quos tempora! Dolore eveniet, impedit laborum modi nesciunt nisi obcaecati quod sequi sunt totam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto assumenda at atque aut cupiditate delectus earum illo in iste iure minus molestias, nisi officiis quibusdam quos, rem, saepe soluta veniam! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad architecto est eveniet explicabo in, incidunt itaque, perspiciatis quod, ratione sed sunt suscipit ut! Ea error explicabo nihil nobis quaerat quos?</p>
    </div>
        
    )
}
export default Guide