import bosPng from '../assets/bos.png'
import config from '../config';
export const titleUrlDeleteBaskSpace = (title) => {
    return title !== undefined ?  title.replace(/ /g , "_") : "";
  }
  export const getItemPrimaryImage = (img) => {
    return img !== undefined && img !== "" ?  img : bosPng;
  }

export const getDetailLink = (item) => {
    const title =item?.title !== undefined ?  item.title.replace(/ /g , "-") : "";
    return item !== undefined ?  `/detay/${item?.id}/${title}` : "#";
  }

  export const getDateLocale = (date) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };
    return new Date(date).toLocaleDateString("tr-TR", options);
}

export const getItemUrl = (item)=>{

    return `${config.appUrl}/detay/${item.id}/${encodeURIComponent(titleUrlDeleteBaskSpace(item.title))}`
}
export const getItemPath = (item)=>{

  return `/detay/${item.id}/${encodeURIComponent(titleUrlDeleteBaskSpace(item.title))}`
}

export function getFullName(user){
  return user.title !== "" ? user.title +' '+ user.name +' '+ user.surname : user.name +' '+ user.surname ;
}  
