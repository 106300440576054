import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { getItemTitleList, getProjectCategory } from "../api";
import { getDetailLink } from "../utils/utilFunction";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const Items = () => {
  const alphabet = 'ABCÇDEFGHIİJKLMNOÖPRSTUÜVYZ123'.split('');

	const params = useParams()
	const {catId} = params; 

  const [data,setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [selectedChar, setSelectedChar] = useState('');

  const getAPI = async()=>{
  const resSlider = await getItemTitleList();
  const resCat = await getProjectCategory();
   if(resSlider.status){

    console.log('catId ', catId )
    
    setData( catId === undefined ? resSlider.data : resSlider.data.filter(x=>x.categoryId === Number(catId) ));
    setFilteredData(catId === undefined ? resSlider.data : resSlider.data.filter(x=>x.categoryId === Number(catId) ));
    setCategoryData(resCat.data);
    console.log('categoryData', categoryData)    
  
  }
  }
  useEffect(() => {
    getAPI();
  }, [catId])


  useEffect(() => {
   console.log('selectedChar ', selectedChar )

   setFilteredData(selectedChar !== "" ? data.filter(x=>x.title.toUpperCase().startsWith(selectedChar)) : data)

  }, [selectedChar])  

  return (
    <Container>
      <div className="page-text pt-5 mb-0 page_set">
        <h1 className="page_title">Maddeler</h1>
      </div>
    { categoryData.length > 0 && <div className="items-filter pt-3">
      <ul id="items-filter-area2">
      <li> <Link className={`p-1 btn-info ${catId === undefined ? "active" : ""}`}  to={"/maddeler"}>Tümü</Link></li>
      {categoryData.map((cat,index)=><li> <Link className={`p-1 btn-info ${Number(catId) === cat.id ? "active" : ""}`}  key={index} to={"/maddeler/"+cat.id}>{cat.name}</Link></li>)}

      </ul>
      </div>}
      <div className="items-filter pt-3">
        <h6 className="text-center mb-3 d-block d-md-none">Filtre Seçiniz</h6>
      <ul id="items-filter-area">
        <li  className={selectedChar === "" ? "btn-info active" : ""} data-filter="all" style={{ minWidth: "50px" }}  onClick={()=>setSelectedChar("")} >
          <span>Tümü</span>
        </li>
        {alphabet.map((letterOrNumber, index) => (
          <li className={selectedChar === letterOrNumber ? "btn-info active" : ""} key={index}  data-filter={letterOrNumber} onClick={()=>setSelectedChar(letterOrNumber)}>
            <span>{letterOrNumber}</span>
          </li>
        ))}
      </ul>
      </div>
      <div className="filter_result">
        <ul id="categorylist" className="category-list">
          {
              filteredData.map((item,index)=> <li key={"scb"+index}>
              <a href={getDetailLink(item)}>{item.title} </a>
            </li>)
          }
          
          
        </ul>
      </div>
    </Container>
  );
};

export default Items;
