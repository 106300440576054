import React, { useEffect, useState } from "react";
import {Container, Row, Col} from 'react-bootstrap';
import UserCardWrapper from "./UserCardWrapper"
import UserCard from "./UserCard"
import PlaceCard from "./PlaceCard"
import { Link } from 'react-router-dom'; 
import c1 from "../assets/turk-devletleri.jpg";
import avatar from "../assets/1.jpg";
import Categories from "./Categories"
import { getItemDailyWeeklyMostView, getItemListSize, getItemSliderList, getProjectApi, getProjectCategory } from "../api";
import config from "../config";

const HomeSection = () => {

    const [data1, setData1] = useState([]);

    const [categoryList, setCategoryList] = useState([]);

    const getAPI = async()=>{


	const res =  getProjectCategory();			
	const res1 =  getProjectApi(config.project.id);	
	const res2 =  getItemDailyWeeklyMostView();			
	const res3 =  getItemListSize(6);

	Promise.all([res,res1,res2,res3]).then((sonuclar)=>{
		console.log("sonuclar ",sonuclar);
        setData1(sonuclar[3].data);
	})

    }
    useEffect(() => {
      getAPI();
    }, [])




    return (
        <div className="home-sections">
            <Container>
                <div className="d-flex justify-content-between">
                    <h2 className="title _set">Ansiklopedide Yeni</h2>
                    <Link className="seeAll" to="/maddeler">Tümü</Link>
                </div>
                <UserCardWrapper>
                <Row>
                        {data1.map((item, i) => (
                              <Col key={i} xs={6} className="px-3 px-md-5">
                                <UserCard info={item} />
                              </Col>
                            ))}
                    </Row>
                </UserCardWrapper>
                        

                <div className="view_count">Görüntülenme Sayısı: 7.075.912</div>
                

            </Container>
        </div>
    )
}
export default HomeSection