import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import ReleatedItem from "../components/ReleatedItem";
import { getDetailLink } from "../utils/utilFunction";
import { selectedProject } from "../config";

const ReleatedItems = () => {
  const location = useLocation();

  console.log('props.location.state ', location )
  
  const [item, setItem] = useState(null);
  const [relationItemList, setRelationItemList] = useState([]);

  useEffect(() => {
    if(location.state !== null)
    {
      setItem(location.state.item);
      setRelationItemList(location.state.relationItemList);
    }
  }, [location])
  

  return (
    <div>
      <div className="page-text page_set page-header">
        <Container>
          <h1 className="page_title">
            {item?.title}
            <small>ile ilişkili maddeler</small>
            <small>
              <Link to={getDetailLink(item)}>« Maddeye Dön</Link>
            </small>
          </h1>
        </Container> 
      </div>

      <div className="page-content related-items mt-4">
        <Container>
          <div className="container-fluid">
            <div className="page-text">
              <blockquote>
                <i>
                  {selectedProject.name} editörleri tarafından tespit edilen
                  ilişkili maddeler aşağıda sunulmaktadır. Fakat, {item?.title} maddesi ile arasında alaka kurulabilecek
                  ansiklopedi maddeleri bunlarla sınırlı olmayabilir.
                </i>
              </blockquote>
              <hr />
            </div>
            <div className="items-list">
              <div className="card-columns">
                {relationItemList.map((rItem,i)=>  <ReleatedItem item={rItem} key={i}/>)}
                {/* <ReleatedItem />
                <ReleatedItem />
                <ReleatedItem />
                <ReleatedItem />
                <ReleatedItem />
                <ReleatedItem />
                <ReleatedItem />
                <ReleatedItem />  */}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ReleatedItems;
