import React from 'react';

const UserCardWrapper = ({ children }) => {
  return (
    <div className="userCardWrapper">
        {children}
    </div>
  );
};

export default UserCardWrapper;
