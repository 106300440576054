import { selectedProject } from "../../config"

const Tag = () => {
    return (
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>
                <b>ISBN NUMARASI</b>
              </td>
              <td>{selectedProject.tagInfo.isbn}</td>
            </tr>
            <tr>
              <td>
                <b>YAYIN NO</b>
              </td>
              <td>{selectedProject.tagInfo.yayinNo}</td>
            </tr>
            <tr>
              <td>
                <b>ESER ADI</b>
              </td>
              <td>{selectedProject.tagInfo.eserAdi}</td>
            </tr>
            <tr>
              <td>
                <b>YAYIMCI HUKUKÎ ADI</b>
              </td>
              <td>{selectedProject.tagInfo.yayimci}</td>
            </tr>
            <tr>
              <td>
                <b>YAYIMCI MARKA ADI</b>
              </td>
              <td>{selectedProject.tagInfo.yayimciMarkaAdi}</td>
            </tr>
            <tr>
              <td>
                <b>YAYINLANDIĞI ORTAM TÜRÜ</b>
              </td>
              <td>{selectedProject.tagInfo.yayinlandigiOrtamTuru}</td>
            </tr>
            <tr>
              <td>
                <b>YAYINLANDIĞI ORTAM</b>
              </td>
              <td>{selectedProject.tagInfo.yayinlandigiOrtam}</td>
            </tr>
            <tr>
              <td>
                <b>ORTAM TÜRÜ</b>
              </td>
              <td>{selectedProject.tagInfo.ortamTuru}</td>
            </tr>
            <tr>
              <td>
                <b>ERİŞİME AÇILMA TARİHİ</b>
              </td>
              <td>{selectedProject.tagInfo.erisimeAcilmaTarihi}</td>
            </tr>
            <tr>
              <td>
                <b>İNTERNETTE ERİŞİM ADRESİ</b>
              </td>
              <td>{selectedProject.tagInfo.erisimAdresi}</td>
            </tr>
            <tr>
              <td>
                <b>YAYIN ERİŞİM TÜRÜ</b>
              </td>
              <td>{selectedProject.tagInfo.erisimTuru}</td>
            </tr>
          </tbody>
        </table>
    )
}
export default Tag