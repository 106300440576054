import { Carousel } from 'react-bootstrap';
import PlaceCard from "./PlaceCard";

function Categories({ info , ...props }) {
  console.log('info ', info )
  return (
    
        <Carousel indicators={false} data-bs-theme="dark"  {...props}>
         
        {Array(8)
              .fill(0)
              .map((_, i) => (
                  <Carousel.Item>
                    <PlaceCard info={info} key={i} />
                  </Carousel.Item>
              ))}
              
        </Carousel>

  );
}

export default Categories;