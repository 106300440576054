import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Icons from "../assets/Icons";
import { Link } from "react-router-dom";
import footerText from "../assets/footer-text.png";
import nanomedya from "../assets/ayk-logo.png";
import config, { selectedProject } from "../config";

const Footer = () => {
  const [popup, setPopup] = useState(false)

  const handleStorage = event => {
    event.preventDefault();
    localStorage.setItem("popup", "true");
    setPopup(false)
  }

  useEffect(() => {
    const pop = localStorage.getItem("popup");
    if(!pop){
      setPopup(true)
    }
  }, [])


  return (
    <footer>
      <Container className="py-5">
        <Row> 
          <Col className="d-flex flex-wrap  justify-content-center align-items-center _links">
            <div className="_quick_title">Hızlı Erişim</div>
            <Link to="/hakkinda">Hakkında</Link>
            <Link to="/maddeler">Maddeler</Link>
            <Link to="/yazarlar">Yazarlar</Link>
            <Link to="/iletisim">İletişim</Link>
              
            <div className="d-flex justify-content-center justify-content-md-end align-items-center social_links_footer">
              <Link to={config.project.social.facebook}>
                <div
                  dangerouslySetInnerHTML={{ __html: Icons.facebook }}
                  className="_icon"
                />
              </Link>
              <Link to={config.project.social.twitter}>
                <div
                  dangerouslySetInnerHTML={{ __html: Icons.x }}
                  className="_icon"
                />
              </Link>
              <Link to={config.project.social.instagram}>
                <div
                  dangerouslySetInnerHTML={{ __html: Icons.instagram }}
                  className="_icon"
                />
              </Link>
              <Link to={config.project.social.youtube}>
                <div
                  dangerouslySetInnerHTML={{ __html: Icons.youtube }}
                  className="_icon"
                />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="_footer_bottom">
        <Container>
          <Row className="justify-content-center align-items-center">
            <img src={footerText} className="img-fluid w-auto" />
          </Row>
        </Container>
      </div>
      <div className="footer-line">
        <Container>
          <Row>
            <Col lg={9}>
              <div className="copyright">
                <span>© 2024 Copyright</span>
                <a href={selectedProject.instUrl} target="_blank">
                  {selectedProject.instLongName}
                </a>
                <span>Her Hakkı Saklıdır. </span>
                <a href="#">Sorumluluk Reddi</a>
                <span>|</span>
                <a href="#">Gizlilik Politikası</a>
              </div>
            </Col>
            <Col lg={3}>
              <div className="nanomedya text-center text-md-right">
                <a
                  href="https://www.ayk.gov.tr"
                  target="_blank"
                  title="Atatürk Kültür, Dil ve Tarih Yüksek Kurumu"
                >
                  <img src={nanomedya} alt="AYK" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {popup && (
      <div id="kvkk" className="kvkk">
        <p>
          Size daha iyi bir deneyim sağlamak için bazı çerezleri kullanmaktayız.
          Çerezler hakkında daha fazla bilgi almak için{" "}
          <a href="/policy">Gizlilik ve Çerez Politikası</a> sayfamızı
          ziyaret edebilirsiniz.
        </p>{" "}
        <button id="kvkkAccept" className="btn btn-kvkk" onClick={(e) => handleStorage(e)}>
          <span>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="check"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              data-fa-i2svg=""
              className="svg-inline--fa fa-check"
            >
              <path
                fill="currentColor"
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
              ></path>
            </svg>
          </span>{" "}
          Tamam
        </button>
      </div>
      )}
    </footer>
  );
};

export default Footer;
