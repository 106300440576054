const Contributors = () => {
    return (
       
        <table className="table table-striped">
		<thead>
			<tr role="row">
				<th>AD-SOYAD</th>
				<th>KURUM</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td class="column-1">Prof. Dr. Adnan SOFUOĞLU</td>
				<td class="column-2">Atatürk Araştırma Merkezi Eski Başkanı</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Cemalettin TAŞKIRAN</td>
				<td class="column-2">Ankara Hacı Bayram Veli Üniversitesi</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Cezmi ERASLAN</td>
				<td class="column-2">Atatürk Araştırma Merkezi Eski Başkanı</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Esat ARSLAN</td>
				<td class="column-2">Çağ Üniversitesi</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Hale ŞIVGIN</td>
				<td class="column-2">Ankara Hacı Bayram Veli Üniversitesi</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Halil BAL</td>
				<td class="column-2">İstanbul Üniversitesi</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Hamit PEHLİVANLI</td>
				<td class="column-2">Kırıkkale Üniversitesi</td>
			</tr>
			<tr>
				<td class="column-1">Dr. Hüseyin AĞCA</td>
				<td class="column-2">Türk Dünyası Ansiklopedisi Projesi Eski Genel Sekreteri</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. İhsan GÜNEŞ</td>
				<td class="column-2">Yeditepe Üniversitesi</td>
			</tr>
			<tr>
				<td class="column-1">Dr. İsmet GÖRGÜLÜ</td>
				<td class="column-2">Emekli Öğr. Üyesi</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. İzzet ÖZTOPRAK</td>
				<td class="column-2">Emekli Öğr. Üyesi</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Kemal ÇELİK</td>
				<td class="column-2">Gümüşhane Üniversitesi</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Mehmet Akif TURAL</td>
				<td class="column-2">Ankara Hacı Bayram Veli Üniversitesi</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Mehmet Ali BEYHAN</td>
				<td class="column-2">Atatürk Araştırma Merkezi Eski Başkanı</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Mustafa Sıtkı BİLGİN</td>
				<td class="column-2">Artvin Çoruh Üniversitesi Rektörü</td>
			</tr>
			<tr>
				<td class="column-1">Nâsır YÜCEER</td>
				<td class="column-2">Emekli Albay</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Neşe ÖZDEN</td>
				<td class="column-2">Ankara Üniversitesi</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Nizami CAFEROV</td>
				<td class="column-2">Azerbaycan Atatürk Araştırma Merkezi Eski Başkanı</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Osman HORATA</td>
				<td class="column-2">Hacettepe Üniversitesi</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Oğuz AYTEPE</td>
				<td class="column-2">Emekli Öğr. Üyesi</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Ömer Osman UMAR</td>
				<td class="column-2">Fırat Üniversitesi</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Sadık TURAL</td>
				<td class="column-2">Atatürk Kültür, Dil ve Tarih Yüksek Kurumu Eski Başkanı</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Selami KILIÇ</td>
				<td class="column-2">Atatürk Üniversitesi</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Selma YEL</td>
				<td class="column-2">Gazi Üniversitesi</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Süleyman BEYOĞLU</td>
				<td class="column-2">Marmara Üniversitesi</td>
			</tr>
			<tr>
				<td class="column-1">Prof. Dr. Utkan KOCATÜRK</td>
				<td class="column-2">Atatürk Araştırma Merkezi Eski Başkanı</td>
			</tr>
			<tr>
				<td class="column-1">Doç. Dr. Yüksel ÖZGEN</td>
				<td class="column-2">Atatürk Araştırma Merkezi Başkanı</td>
			</tr>
		</tbody>
	</table>
        
    )
}
export default Contributors