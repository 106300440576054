import React from "react"
import Icons from "../assets/Icons"
import { Link } from "react-router-dom";
import { getDateLocale, getDetailLink, getItemPrimaryImage } from "../utils/utilFunction";

const ReleatedItem = ({item}) => {
  return (
    <div className="card">
      <Link to={getDetailLink(item)}>
        <img
          alt={item.title} 
          className="card-img-top"
          src={getItemPrimaryImage(item?.primaryImage)}
        />
        <div className="card-body">
          <h3 className="card-title">{item?.title}</h3>
          <h5 className="card-subtitle">{item?.definition}</h5>
          <p className="card-text d-flex gap-2">
            <small>
              <span className="d-flex align-items-center">
                <span
                  dangerouslySetInnerHTML={{ __html: Icons.calendar }}
                  className="_icon"
                />
                {getDateLocale(item?.updateDate)}
              </span>
            </small>
            <small>
              <span className="d-flex align-items-center">
                <span
                  dangerouslySetInnerHTML={{ __html: Icons.eye }}
                  className="_icon"
                />
                 {item?.viewCount}
              </span>
            </small>
          </p>
        </div>
      </Link>
    </div>
  );
};
export default ReleatedItem;
