import React from "react";
import { Form, Container, Button } from "react-bootstrap";
import Icons from "../assets/Icons";
import config from "../config";

const Contact = () => {

  return (
    <Container>
      <div className="page-text pt-5 mb-0 page_set">
        <h1 className="page_title">İletişim</h1>
      </div>
      <div className="page-content contact pt-3">
     
        <div className="row">
            <div className="col-lg-5 col-12">
                <div className="contact-box contact-information">
                    <h3>İletişim Bilgilerimiz</h3>
                    <p><span
                        dangerouslySetInnerHTML={{ __html: Icons.home }}
                        className="_icon"/>{config.project.contact.address}</p>
                    <p><span
                        dangerouslySetInnerHTML={{ __html: Icons.phone }}
                        className="_icon"/> <a href="tel:#">{config.project.contact.address}</a></p>
                    <p><span
                        dangerouslySetInnerHTML={{ __html: Icons.home }}
                        className="_icon"/> <a href="tel:#">{config.project.contact.tel}</a></p>
                    {/* <p><span
                        dangerouslySetInnerHTML={{ __html: Icons.fax }}
                        className="_icon"/> <a href="tel:#">+90 (312) 285 6573</a></p>
                   */}
                    <p> 
                        <span
                        dangerouslySetInnerHTML={{ __html: Icons.email }}
                        className="_icon"/> <a href={"mailto:"+ config.project.contact.mail}>{config.project.contact.mail}</a></p>
                </div>
                <div className="contact-box map_wrapper mt-5">
                    <h3>Harita</h3>
                    <div dangerouslySetInnerHTML={{__html: config.project.contact.map}}>

                    </div>
                </div>
            </div>
            <div className="col-lg-1 col-12"></div>
            <div className="col-lg-6 col-12">
                <div className="contact-box contact-form mb-4">
                    <h3>İletişim Formu</h3>
                    <Form method="POST">
                        <Form.Group className="mb-3">
                            <Form.Label>Adınız ve Soyadınız</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>E-posta Adresiniz</Form.Label>
                            <Form.Control type="email" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Mesajınız</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                        </Form.Group>
                        <Button variant="info" type="submit" className="text-white">
                            Gönder
                        </Button>
                    </Form>
 
                </div>
            </div>
        </div>
        
      </div>
    </Container>
  );
};

export default Contact;
