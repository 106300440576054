import React, { useEffect, useState } from 'react';
import MyCarousel from '../components/Carousel';
import HomeSection from '../components/HomeSection'; 
import { getItemSliderList } from '../api';

const Home = () => {

    const [sliderItemList,setSliderItemList] = useState([]);

    const getAPI = async()=>{
    const resSlider = await getItemSliderList();
     if(resSlider.status){
          setSliderItemList(resSlider.data);
        }
    }
    useEffect(() => {
      getAPI();
    }, [])



  return (
    <div>
        <MyCarousel data={sliderItemList}/>
        <HomeSection />
    </div>
  );
};

export default Home;
