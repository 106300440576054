import React from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { About, Tag, WorkGroup, Contributors, Guide } from "./Modules"

const Page = () => {

  const {slug} = useParams()

  const pages = [
    {
      slug: 'hakkinda',
      title: 'Hakkında',
      module: About
    },
    {
      slug: 'kunye',
      title: 'Künye',
      module: Tag
    },
    {
      slug: 'calisma-grubu',
      title: 'Çalışma Grubuu',
      module: WorkGroup
    },
    {
      slug: 'emegi-gecenler',
      title: 'Emeği Geçenler',
      module: Contributors
    },
    {
      slug: 'kılavuz',
      title: 'Kılavuz',
      module: Guide
    }
  ] 

  
  const selectedModule = pages.find(page => page.slug === slug)

  if (!selectedModule) {
    return <div>Sayfa bulunamadı</div>
  }

  const ModuleComponent = selectedModule.module


  return (
    <Container>
      <div class="page-text pt-5 mb-0 page_set">
        <h1 className="page_title">{selectedModule.title}</h1>
      </div>
      <div class="page-text py-5 page_set">
           <ModuleComponent />
      </div>
    </Container>
  );
};

export default Page;
