import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, Navigate, useParams } from "react-router-dom";
import { getItemByCategoryId, getProjectCategory } from "../api";
import Icons from "../assets/Icons";
import { getDetailLink } from "../utils/utilFunction";

const CategoryDetail = () => {
	const [categoryList, setCategoryList] = useState([]);
	const [currentCategory, setCurrentCategory] = useState({});
	const [currentCategoryItems, setCurrentCategoryItems] = useState([]);

	const params = useParams()
	const {id} = params; 

	const getAPI = async()=>{
		const res = await getProjectCategory();
		 if(res.status){
			setCategoryList(res.data);
				console.log('res.data ', res.data )
			setCurrentCategory(res.data.find(x=>x.id === Number(id)))
	
			}
			
 
		}
		useEffect(() => {
		
			getAPI();
		  }, [])
  

		  
	const getCategoryAPI = async()=>{
		const res = await getItemByCategoryId(currentCategory.id);
		 if(res.status){
			setCurrentCategoryItems(res.data);

	
			}
			else
			setCurrentCategoryItems([])

		}

		  useEffect(() => {
		
			getCategoryAPI();
		  }, [currentCategory])
  

			  




		  const onRowSelect = (event) => {
			console.log(event);
		   Navigate(getDetailLink(event.data))
	   };
	   const formatDate = (dateString) => {
		 const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
		 return new Date(dateString).toLocaleDateString('tr-TR', options);
	   };
  return (
    <div className="mb-4">
      <div className="page-text page-header page_set">
        <Container>
          <h1 className="page_title">{currentCategory.name}</h1>
        </Container>
      </div>

      
      <Container>
		<div className="row">
			<div className="col-lg-3 col-12">
				<div className="category-list-area">
					<h3>Tüm Kategoriler</h3>
					<ul>
						{categoryList.map((cat,index)=><li key={index} className={ cat.id === Number(currentCategory.id) ? "active" : ""}>
							<Link href="/kategori-detay" onClick={()=>setCurrentCategory(cat)} >{cat.name}</Link>
						</li>)}
					
					</ul>
				</div>
			</div>
            
			<div className="col-lg-9 col-12">
				<div className="page-text">
					<p>{currentCategory.explain}</p>
                    <a className="btn btn-info text-white d-inline-flex gap-2 align-items-center" href={"/maddeler/"+currentCategory.id}>Kategorinin Tüm Maddelerini Listele
                    <span dangerouslySetInnerHTML={{ __html: Icons.arrowRight }} className="_icon"/></a>

					<div className="mt-2">
					
						<DataTable value={currentCategoryItems} 
        emptyMessage="Madde bulunamadı"
        filterDisplay="row"
        selectionMode="single"
        onRowSelect={onRowSelect}
   
        showGridlines
        
        paginator 
        filterLocale="tr"
        rows={5} 
        rowsPerPageOptions={[5, 10, 25, 50]}
        tableStyle={{ minWidth: '50rem' }}> 
            <Column field="updateDate"  sortable header="Tarih"   body={(rowData) => formatDate(rowData.updateDate)} />
            <Column field="title" header="Madde" />
            <Column field="definition" header="Tanım" />

        </DataTable>

					</div>
				</div>
			</div>
        </div>
        </Container>

    </div>
  );
};

export default CategoryDetail;
