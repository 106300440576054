import React from "react";
import ataturk from "../../assets/ataturk.jpeg";

const About = () => {
    return (
        <div class="page-text py-5 page_set">
        <img alt="Mustafa Kemal Atatürk" src={ataturk} width={300} style={{display: 'inline-block;', padding: '5px', border: '1px solid rgb(221, 221, 221)', float: 'left', margin: '0px 15px 15px 0px'}} />
        <p>
          Atatürk Kültür, Dil ve Tarih Yüksek Kurumu, Atatürk Araştırma Merkezi
          Başkanlığınca, Gazi Mustafa Kemal Atatürk’ün doğumundan ölümüne kadar
          uzanan süreçte; yaşadığı mekânlar, başlattığı, yönettiği ya da
          yönlendirdiği, Türk ulusunun geleceğinin şekillenmesinde, toplumun
          yapılanmasında etkili olan olay ve olgular, gerçekleştirdiği inkılâplar
          ve dönemin tarihî şahsiyetleri hakkında birincil, güvenilir kaynaklara
          dayalı bilgi vermek amacıylaAtatürk Ansiklopedisi hazırlanmasına
          başlanmıştır. Eserin hazırlanmasını gerçekleştirmek, bilimsel ve teknik
          denetimini yapmak üzere bir çalışma grubu oluşturulmuştur. Bu doğrultuda
          ansiklopediye katkı sağlayacak yazarların bilgisine sunulmak üzere Temel
          Yazım İlkeleri hazırlanmıştır.
        </p>
        <p>
         Atatürk Ansiklopedisi’nde yer alacak konuların belirlenmesinde,
          bizzat Atatürk tarafından kaleme alınan kaynaklara öncelik verilmiştir.
          Bu kaynaklarda sözü edilen mekân, olay ve kişilerle ilgili bilgilerin;
          tarihî süreç içinde, konunun uzmanlarınca yazılmış eser ve belgelere
          dayalı olarak ortaya konulması esas alınmıştır. Ayrıca yerli ve yabancı
          arşivlerde bulunan her çeşit bilgi ve belgelerden faydalanılması
          öngörülmüştür.
        </p>
        <p>
          Çalışma grubunca; uzmanlık alanları dikkate alınarak tespit edilen
          yazarlar, madde metinlerini belirlenen ilkelere göre kaleme alarak,
          Kurumumuza teslim etmişlerdir. Hazırlanan madde metinleri, çalışma grubu
          üyelerinin hakemliğinde incelenerek, bu yazılarda; ifade, üslûp, yöntem
          ve teknik birliği sağlamak amacıyla gerekli görülen değişiklik ve
          düzenlemeler yapılarak son şeklini almıştır. Metinler üzerinde yapılan
          değişikliklerin oranı hiçbir durumda ana metnin 1/4’ünü geçmemiştir.
        </p>
        <p>
          Gelen yazılar, içerik ve yayın ilkelerine uygun bulunmaması durumunda,
          hakemlik sürecinde yazarına iade edilmiştir. Maddelerde çok önemli ve
          hacimli düzeltme ve değişiklik önerilmesi halinde metinler, yazarlarına
          tekrar gözden geçirilmek üzere gönderilmiştir. Kullanılan kaynaklar,
          yaygın bilimsel yöntemlere göre metin sonunda gösterilmiştir. Yazarlar,
          metinlerinde yer almasını uygun gördükleri resim, grafik, harita vb.
          görsel malzemeyi yazılarına eklemişlerdir.
        </p>
        <p>
          Madde yazımını kabul eden yazarlardan, metinlerinin Mustafa Kemal
          Atatürk’ü özgün ve güvenilir kaynaklara dayalı olarak ve en doğru tarzda
          tanıtacak nitelikte ve anlaşılır bir dille ele almaları istenmiştir. Her
          maddenin içeriğine uygun bir hacimde olmasına dikkat edilmiştir.
          Maddeler yazarlar tarafından kaleme alınırken konunun Atatürk’le
          ilgisinin kurulmasına özen gösterilmiştir.
        </p>
        <p>
          29 Ekim 2020 tarihi itibariyle dijital ortamda erişime açılmış olan Türk
          Dünyası Ansiklopedisi’nde 800’den fazla konu başlığı yer almaktadır. Bu
          konuların büyük bir kısmı erişime açılmış olup kalan maddelerin
          yayımlanması kısa süre içerisinde tamamlanacaktır. Türk Dünyası
          Ansiklopedisi’nin dijital ortamda yayımlanmış olması, geniş kitlelere
          erişiminin yanı sıra onun sürekli güncellenmesini, yeni bilgi ve
          bulgular doğrultusunda ortaya çıkan istek, öneri ve eleştirilerin
          dikkate alınmasını sağlayacaktır.
        </p>
        <p>
          Cumhuriyet’in ilanının 97.yılında Gazi Mustafa Kemal Atatürk’e minnet ve
          şükranlarımızın bir nişanesi olarak, bu nadide eserin ortaya çıkmasında
          görev alan akademisyenlere, kurum çalışanlarına, Türk Dünyası
          Ansiklopedisi Çalışma Grubu başkan ve üyelerine Atatürk Araştırma
          Merkezi Başkanlığı olarak teşekkür ederiz.
        </p>
        <p>
          <strong>ATATÜRK ARAŞTIRMA MERKEZİ BAŞKANLIĞI</strong>
        </p>
      </div>
    )
}
export default About