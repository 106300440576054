import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { useNavigate, useParams } from "react-router-dom";
import { getItemListByUserId, getWriterById } from '../api';
import config from '../config';
import { getDetailLink } from '../utils/utilFunction';


const AuthorDetail = () => {
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [writer, setWriter] = useState({});
  const [writerItemList, setWriterItemList] = useState([]);
  const navigate = useNavigate(); 

  const [filters, setFilters] = useState({
    title: { value: null, matchMode: FilterMatchMode.CONTAINS },
    definition: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const params = useParams()
const {id} = params; 
   
  const getAPI = async()=>{
    const res = await getWriterById(id);
     if(res.status){
      setWriter(res.data);

     const res2= await getItemListByUserId(id)
      setWriterItemList(res2.data)

        }
 

        	



    }
    useEffect(() => {
      console.log('id ', id )
      if(id!== undefined)
      getAPI();
    }, [id])
   

const clearFilter = () => {
    initFilters();
};

const initFilters = () => {
    setFilters({
        title: { value: null, matchMode: FilterMatchMode.CONTAINS },
       // updateDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        definition: { value: null, matchMode: FilterMatchMode.CONTAINS },

    });
    setGlobalFilterValue('');
};

const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    console.log('value ', value )
    let _filters = { ...filters };

    _filters['title'].value = value;
    _filters['definition'].value = value;

    console.log('_filters ', _filters )

    setFilters(_filters);
    setGlobalFilterValue(value);
};

const onRowSelect = (event) => {
     console.log(event);
    navigate(getDetailLink(event.data))
};
const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  return new Date(dateString).toLocaleDateString('tr-TR', options);
};
const renderHeader = () => {
    return (
        <div className="d-flex justify-content-between">
            <Button icon="pi pi-filter-slash" label="Temizle" size="small"  onClick={clearFilter} />
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Ara" />
            </span>
        </div>
    );
};
const header = renderHeader();
const name = writer?.name+ " " + writer?.surname
  return (

    
    <div className="mb-3">
      <div className="page-text page-header page_set">
        <Container>
          <h1 className="page_title">{name}</h1>
        </Container>
      </div>

    <Container>
        
    <div className="page-text author-detail">
		<img alt="Demo" src={config.userAuthApiUrl + writer?.profileImageUrl} onError={(e)=>{e.target.onerror = null; e.target.src=require("../assets/user.png")}} style={{ width: '150px', height: '150px', margin: '0px 15px 15px 0px', float: 'left', padding: '5px', border: '1px solid rgb(221, 221, 221)' }} />
<p>{writer?.cv}</p>
        <hr />



       

      </div>
      <div className='row'>
<h2>{name} Maddeleri</h2>
<DataTable value={writerItemList} filters={filters} header={header}
        emptyMessage="Sonuç bulunamadı"
        filterDisplay="row"
        selectionMode="single"
        onRowSelect={onRowSelect}
        selection={selectedItem}
        showGridlines
        removableSort
        paginator 
        filterLocale="tr"
        rows={5} 
        rowsPerPageOptions={[5, 10, 25, 50]}
        tableStyle={{ minWidth: '50rem' }}> 
            <Column field="updateDate"  sortable header="Tarih"   body={(rowData) => formatDate(rowData.updateDate)} />
            <Column field="title" header="Madde" />
            <Column field="definition" header="Tanım" />

        </DataTable>


</div>
    </Container>
      </div>
  );
};

export default AuthorDetail;
