import {Container, Carousel, Image} from 'react-bootstrap';
import s1 from '../assets/s1.jpg';
import s2 from '../assets/s2.jpg';

import sider1 from '../assets/slider/slider1.jpg';
import sider2 from '../assets/slider/slider2.jpg';
import sider3 from '../assets/slider/slider3.jpg';

function MyCarousel({data}) {
  return (
    <Container>
        <Carousel data-bs-theme="dark" className='mt-4 mainCarousel'>
        
        <Carousel.Item>
            <Image src={sider1} fluid className='w-100' />
        </Carousel.Item>
        <Carousel.Item>
            <Image src={sider2} fluid className='w-100' />
        </Carousel.Item>
        <Carousel.Item>
            <Image src={sider3} fluid className='w-100' />
        </Carousel.Item>
{/* 
        <Carousel.Item>
            <Image src={s1} fluid className='w-100' />
        </Carousel.Item>

        <Carousel.Item>
            <Image src={s2} fluid className='w-100' />
        </Carousel.Item>

        {data.map((item,key)=>
           <Carousel.Item key={key}>
           <Image src={s2} fluid className='w-100' />
       </Carousel.Item>
        )} */}
        </Carousel>
    </Container>
  );
}

export default MyCarousel;