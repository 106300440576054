import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getDateLocale, getDetailLink, getItemPrimaryImage } from "../utils/utilFunction";

const UserCard = ({ info }) => {
  return (
    <div className="cardWrapper"> 
      <div className="image">
      <Link to={getDetailLink(info)}>
          <Image alt={info.title} src={ getItemPrimaryImage(info.primaryImage)} />
        </Link>
      </div>
      <div className="text">
      <div className="publish-date">{ getDateLocale(info.updateDate)}</div>
        <div className="title">
          <Link to={getDetailLink(info)}>{info.title}</Link>
        </div>
        <div className="sub-title">{info.definition}</div>
        <div className="summary">{info.subText}</div> 
        <div className="authors">
        {info.writerName?.split(",").map((item, key) => (
            <Link to={info.userIdList === "" ? `/yazar-detay/${info.userId}/${item}`  : `/yazar-detay/${info.userIdList.split(",")[key]}/${item}`} key={key}>
              {item}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserCard;
